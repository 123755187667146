<template>
  <div class="zjzs mrow">
    <div class="wrapper">
      <div class="mtit1">
        <h2 class="cn">专家展示</h2>
        <div class="en">Expert show</div>
        <div class="line"></div>
      </div>
      <div class="zjzs-list">
        <div class="swiper-container">
          <ul class="swiper-wrapper">
            <li class="swiper-slide">
              <div class="img">
                <img src="../../../assets/images/img53.png" />
              </div>
              <h3>须贺唯知</h3>
              <h4>日本科学委员会(SCJ)委员</h4>
            </li>
            <li class="swiper-slide">
              <div class="img">
                <img src="../../../assets/images/img54.png" />
              </div>
              <h3>马旭·比尼亚</h3>
              <h4>澳洲工程院院士及美国机械工程协会院士</h4>
            </li>
            <li class="swiper-slide">
              <div class="img">
                <img src="../../../assets/images/img55.png" />
              </div>
              <h3>戈尔巴乔夫·谢尔盖·维克托罗维奇</h3>
              <h4>俄罗斯自然科学院院士</h4>
            </li>
            <li class="swiper-slide">
              <div class="img">
                <img src="../../../assets/images/img56.png" />
              </div>
              <h3>皮特・萨赫逊</h3>
              <h4>德国国家科学与工程院（Acatech）院士</h4>
            </li>
          </ul>
        </div>
        <div class="swiper-button-prev zjzs-prev"></div>
        <div class="swiper-button-next zjzs-next"></div>
      </div>
    </div>
  </div>
  
</template>
<script>
import Swiper from "@/libs/js/swiper.min";
import { newRouter } from "@/libs/comm";
export default {
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    //供需对接-合作案列
    this.getArticleList();
  },
  methods: {
    toRouter() {
      newRouter("apply");
    },
    swiperInit() {
      new Swiper(".zjzs-list .swiper-container", {
        slidesPerView: 4,
        spaceBetween: 30,
        loop: true,
        autoplayDisableOnInteraction: false,
        autoplay: 4000,
        nextButton: ".zjzs-next",
        prevButton: ".zjzs-prev",
        breakpoints: {
          1000: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      });
    },
    //合作案列
    getArticleList() {
      this.api
        .getTabArticleList({ pageNo: 1, pageSize: 20, type: 12 })
        .then((res) => {
          const {
            data: { result },
          } = res;
          const { records } = result || [];
          this.list = records;
          setTimeout(() => {
            this.swiperInit();
          }, 500);
        });
    },
  },
};
</script>
<style scoped lang="scss"></style>
