<template>
  <div class="el_personnel">
    <banner />
    <introduce />
    <expert />
    <service />
  </div>
</template>

<script>
import banner from "./banner.vue";
import introduce from "./introduce.vue";
import expert from "./expert.vue";
import service from "./service.vue";
export default {
  data() {
    return {
      msg: "el_investment",
      list1: [],
    };
  },
  created() {},
  components: {
    banner,
    introduce,
    expert,
    service,
  },
  methods: {
    toRouter() {
      this.$router.push({ name: "article" });
    },
  },
};
</script>
<style scoped lang="scss"></style>
