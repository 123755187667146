<template>
  <div class="mbanner">
    <div class="wrapper">
      <div class="mbanner-hd">
        <div class="cn"><h2>高端才智</h2></div>
        <div class="en">High-end talent</div>
      </div>
      <div class="mbanner-bd mbanner-bd1">
        石家庄国际人才城作为石家庄市高新区招才局，<br />
        依托石家庄高新区人力资源和社会保障局，<br />
        按“虚拟机构、实体运行”模式开展工作，采<br />
        用政府外包的方式承揽高新区人才引进培育、政策落实、<br />
        人才项目实施等服务，招才局自2018年成立至今，<br />
        已累计为高新区引进高层次人才、高精尖人才138人。
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.mbanner {
  background-image: url(../../../assets/images/mbanner13.jpg);
}
</style>
