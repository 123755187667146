<template>
  <div>
    <div class="mrow rcyj">
      <div class="wrapper">
        <div class="mtit1">
          <h2 class="cn">高端人才引进</h2>
          <div class="en">High-end talent introduction</div>
          <div class="line"></div>
        </div>
        <div class="rcyj-txt">以平台吸引人才，以人才引领产业升级发展。</div>
        <div class="rcyj-list">
          <ul>
            <li>
              <div class="hd">
                <img class="icon"
                     src="../../../assets/images/icon82.png" />
                <h3>高端引才</h3>
              </div>
              <div class="bd">
                引进诺奖获得者、院士、国家特聘专家等国家级掌握国内外领先技术的人才及团队领军研发的高科技项目，全方位、多层次、高质量地引进海内外高端才智。
              </div>
            </li>
            <li>
              <div class="hd">
                <img class="icon"
                     src="../../../assets/images/icon83.png" />
                <h3>柔性引智</h3>
              </div>
              <div class="bd">
                围绕企业技术难题或项目需要，一对一匹配高端专家，以柔性引进的方式，为企业提供技术咨询、科研难题攻破、等全方位解决方案。
              </div>
            </li>
            <li>
              <div class="hd">
                <img class="icon"
                     src="../../../assets/images/icon84.png" />
                <h3>猎头咨询</h3>
              </div>
              <div class="bd">
                结合区域产业发展需求，为高校、企业、科研院所等机构提供海内外高层次人才猎寻服务，并围绕人才引进、培养、使用提供专业化的咨询服务。
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="gdrc mrow">
      <div class="wrapper">
        <div class="gdrc-list">
          <ul>
            <li class="l1">
              <div class="hd">
                <img class="icon"
                     src="../../../assets/images/icon85.png" />
                <h3>科技成果转化</h3>
                <h4>搭科技成果转化平台，促科研成果落地转化</h4>
              </div>
              <div class="bd">
                根据省市区科技成果转化相关文件，围绕技术转移和成果转化，与国际组织机构、政府共建创新平台，与高校共建科技成果转化中心；依托“中澳火炬创新园-中澳项目创新中心服务平台”
                “青岛大学科技成果转化石家庄中心”等平台聘请高端专家人才为“总顾问”，积极有效助推人才、项目、与企业科研成果落地转化。
              </div>
            </li>
            <li class="l2">
              <div class="hd">
                <img class="icon"
                     src="../../../assets/images/icon86.png" />
                <h3>才智活动举办</h3>
                <h4>搭建国际交流平台，推进人才项目对接</h4>
              </div>
              <div class="bd">
                举办人才论坛、人才项目推介会、行业高峰论坛等各类国际人才交流活动，搭建专业化国际交流平台，汇聚并推广海内外优质人才、项目资源，发挥高端人才独特优势。
              </div>
            </li>
            <li class="l3">
              <div class="hd">
                <img class="icon"
                     src="../../../assets/images/icon87.png" />
                <h3>招才引智平台搭建</h3>
                <h4>汇聚人才项目资源，创建招才引智载体</h4>
              </div>
              <div class="bd">
                以“政府引导、市场化运作”为原则，以人才对接、招才引智、项目推介等为契入点，广聚天下英才，打造国际人才集聚平台、智力成果转化中心、顶级人才创新创业基地，培养和引进高新区建设所需人才。
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { newRouter } from "@/libs/comm";
export default {
  data () {
    return {};
  },
  mounted () { },
  methods: {
    toRouter (path) {
      newRouter(path);
    },
  },
};
</script>
<style scoped lang="scss">
.bd {
  font-size: 15px;
}
</style>
