<template>
  <div class="fwal mrow">
    <div class="wrapper">
      <div class="mtit1">
        <h2 class="cn">服务案例</h2>
        <div class="en">Service Case</div>
        <div class="line"></div>
      </div>
      <div class="fwal-list">
        <ul>
          <li v-for="item in list"
              :key="item.id">
            <div class="img">
              <img :src="item.cover" />
            </div>
            <div class="info">
              <h3 class="tit">{{ item.title }}</h3>
              <div class="txt">
                {{ item.articleAbstract }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      list: [],
    };
  },
  mounted () {
    this.getArticleList();
  },
  methods: {
    //服务案例
    getArticleList () {
      this.api
        .getTabArticleList({ pageNo: 1, pageSize: 20, type: 7 })
        .then((res) => {
          const {
            data: { result },
          } = res;
          const { records } = result || [];
          this.list = records;
        });
    },
  },
};
</script>
<style scoped lang="scss">
.fwal-list {
  .info .txt {
    // height: 310px;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    word-break: break-all;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
  }
}
</style>
